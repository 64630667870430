'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,{
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event = {
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
}
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default is 'pointerdown' then 'pointermove' else if _default is 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default is 'pointerdown' then 'pointerup' else if _default is 'touchstart' then 'touchend' else 'click'
  event.enter = if _default is 'pointerenter' then _default else if _default is 'touchenter' then _default else 'mouseenter'
  event.leave = if _default is 'pointerleave' then _default else if _default is 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on(event.all, elm, (e) ->
    e.preventDefault()
    event.check(e)
    if fn isnt null
      if e.type is event.enter or e.type is event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn(this, e)
      else
        if type is 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on(event.end, (e) ->
            fn(this, e)
            return
          )
        else if type is 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on(event.end, elm, (e) ->
            fn(this, e)
            return
          )
        else if type is 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on(event.end, elm, (e) ->
            fn(this, e)
            return
          )
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  )

$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  $('a[href^="#"]'+'a[href!="#"]').on('click.smoothScroll',
    (e)->
      e.preventDefault()
      $('body,html').animate({
        scrollTop: $($(this).attr('href')).offset().top
      }, 500
      )
      false
  )
  
  touchPos = 0
  threshold = 10
  isFlick = false
  
  $('.js-accordion-title').on('touchstart',
    (e)->
      isFlick = false
      touchPos = e.originalEvent.touches[0].pageY
  )
  
  $('.js-accordion-title').on('touchmove',
    (e)->
      currentPos = e.originalEvent.touches[0].pageY
  
      if Math.abs(touchPos - currentPos) > threshold
        isFlick = true
      else
        isFlick = false
  )
  
  $('.js-accordion-title').click ->
    if $('+.js-accordion-contents', this).css('display') is 'none'
      $(this).addClass 'is-open'
      $('+.js-accordion-contents', this).slideDown 'normal'
    else
      $(this).removeClass 'is-open'
      $('+.js-accordion-contents', this).slideUp 'normal'
    return
  
  $window = $(window)
  $pagetop =$('.js-pagetop')
  
  $(window).scroll(
    ()->
      if $(this).scrollTop() > $(this).height()
        if not $pagetop.hasClass('is-active')
          $pagetop.addClass('is-active')
          console.log('add is-animated')
          $pagetop.addClass('is-animated')
      else
        if $pagetop.hasClass('is-active') and $pagetop.hasClass('is-animated')
          $pagetop.removeClass('is-animated')
  
      $pagetop.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
        ()->
          if $window.scrollTop() > $window.height()
            $pagetop.addClass('is-animated')
          else
            $pagetop.removeClass('is-active')
      )
  )
  
)
